var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Name" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Name", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("b-form-input", {
                              attrs: { placeholder: "", required: "" },
                              model: {
                                value: _vm.feeName,
                                callback: function ($$v) {
                                  _vm.feeName = $$v
                                },
                                expression: "feeName",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Type" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Type", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.feeScheduleType,
                                value: _vm.$route.params.type,
                                state: errors.length > 0 ? false : null,
                                disabled: "",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Status" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "Status", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("b-form-select", {
                              attrs: {
                                options: _vm.feeScheduleStatus,
                                state: errors.length > 0 ? false : null,
                              },
                              model: {
                                value: _vm.feeVersionStatus,
                                callback: function ($$v) {
                                  _vm.feeVersionStatus = $$v
                                },
                                expression: "feeVersionStatus",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._t("first-row"),
        ],
        2
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "Start date" } },
                [
                  _c("b-form-datepicker", {
                    attrs: { id: "start-date", size: "sm" },
                    model: {
                      value: _vm.feeVersionDateStart,
                      callback: function ($$v) {
                        _vm.feeVersionDateStart = $$v
                      },
                      expression: "feeVersionDateStart",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: "End date" } },
                [
                  _c("b-form-datepicker", {
                    attrs: { id: "end-date", size: "sm" },
                    model: {
                      value: _vm.feeVersionDateEnd,
                      callback: function ($$v) {
                        _vm.feeVersionDateEnd = $$v
                      },
                      expression: "feeVersionDateEnd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._t("second-row"),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }